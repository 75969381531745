<script>
  import ValidRange from "../shared/ValidRange.svelte";
  import TimeSpan from "../shared/TimeSpan.svelte";
  import Time from "../shared/Time.svelte";
  import TableReport from "../shared/TableReport.svelte";

  import {
    isValid,
    areIntervalsOverlapping,
    eachDayOfInterval,
    isSameDay,
    format,
    subDays,
    endOfDay,
    startOfDay,
    parseISO,
  } from "date-fns";

  import { by } from "../../utils/sorting";
  import { api } from "../../api";
  import { now, propertyId, queryViewpoint, queryFrom, queryTo } from "../../store";
  import { formatCents } from "../../formatCurrency";

  const title = "Daily Payments Summary";

  $: columns = [
    { name: "Date", sort: (x) => x.day },
    { name: "Net Payout", sort: (x) => x.net },
    { name: "Card Fees", sort: (x) => x.gateway },
    { name: "Service Fees", sort: (x) => x.service },
    { name: "Tax Collected", sort: (x) => x.tax },
    { name: "Total Charges", sort: (x) => x.total },
  ];

  let valid = null;
  let generated = null;

  $: defaultViewpoint = $now;
  $: defaultFrom = subDays($now, 30);
  $: defaultTo = $now;

  $: fetchRows = loadRows($propertyId, $queryViewpoint, $queryFrom, $queryTo);

  async function loadRows(propertyId, viewpoint, from, to) {
    viewpoint = (viewpoint || defaultViewpoint).toISOString();
    from = format(startOfDay(from || defaultFrom), "yyyy-MM-dd'T'HH:mm:ss");
    to = format(endOfDay(to || defaultTo), "yyyy-MM-dd'T'HH:mm:ss");
    const validInterval = `${from}/${to}`;

    const json = await api.get(`payments/collected/metrics`, {
      viewpoint,
      interval: validInterval,
      scope: propertyId,
      payments: ["total", "gateway", "service", "net", "tax"],
      metric: "total",
      datetimes: "P1D",
    });

    valid = json?.metrics?.interval || "/";
    generated = json?.generated;

    return Object.values(
      json.metrics.items.reduce((intervals, item) => {
        if (item.group != "property") return intervals;

        for (const [interval, cents] of Object.entries(item.values)) {
          if (interval.split("/")[0] < item.interval.split("/")[0]) continue;
          if (!intervals[interval]) intervals[interval] = {};
          intervals[interval].interval = interval;
          intervals[interval].datetimes = item.datetimes;
          intervals[interval][item.payments] = cents;
          intervals[interval].timezone = item.timezone;
        }

        return intervals;
      }, {})
    )
      .filter((x) => x.datetimes === "P1D")
      .map((x) => ({
        ...x,
        day: x.interval.split("/")[0],
      }))
      .sort(by("day"));
  }
</script>

{#await fetchRows}
  <TableReport {title} loading={true} />
{:then rows}
  <TableReport {title} {columns} {rows} {valid} {generated}>
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <th scope="row">
        <Time time={row.day} timezone={row.timezone} format="MMM d yyyy" />
      </th>
      <td>{formatCents(row.net)}</td>
      <td>{formatCents(row.gateway)}</td>
      <!-- <td>{formatCents(row.total - row.net)}</td> -->
      <td>{formatCents(row.service)}</td>
      <td>{formatCents(row.tax)}</td>
      <td>{formatCents(row.total)}</td>
    </tr>

    <span slot="controls"><ValidRange {defaultFrom} {defaultTo} /></span>
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
